<template>
    <div class="dynamic-home">
      <div class="dynamic-card-container">
        <div class="dynamic-card">
          <div class="dynamic-card-content">
            <!-- <p class="name">Name: {{ $route.params.name }}</p> -->
            <!-- Row of two columns -->
            <img class="venue-img" src="https://via.placeholder.com/200" alt="Image" />
            <p class="description-text">Description: </p>
            <p class="tonight-text">Upcoming Events:</p>
            <div class="upcoming-events">
                  <div class="grid-row">
                      <!-- First Column -->
                      <div class="grid-column">
                          <DynamicEventCard class="event-card" :name="$route.params.name" district="district1" eventTitle="Event 1" eventDate="Date 1" eventTime="Time 1"/>
                          <p></p>
                          <DynamicEventCard class="event-card" :name="$route.params.name" district="district2" eventTitle="Event 2" eventDate="Date 2" eventTime="Time 2"/>
                      </div>

                      <!-- Second Column -->
                      <div class="grid-column">
                          <DynamicEventCard class="event-card" :name="$route.params.name" district="district3" eventTitle="Event 3" eventDate="Date 3" eventTime="Time 3"/>
                          <p></p>
                          <DynamicEventCard class="event-card" :name="$route.params.name" district="district4" eventTitle="Event 4" eventDate="Date 4" eventTime="Time 4"/>
                      </div>

                      <!-- Third Column -->
                      <div class="grid-column">
                          <DynamicEventCard class="event-card" :name="$route.params.name" district="district5" eventTitle="Event 5" eventDate="Date 5" eventTime="Time 5"/>
                          <p></p>
                          <DynamicEventCard class="event-card" :name="$route.params.name" district="district6" eventTitle="Event 6" eventDate="Date 6" eventTime="Time 6"/>
                      </div>
                  </div>
              </div>
            <div class="row">
              <!-- Left column -->
              <div class="column">
                <p class="live-text">LIVE</p>
                <p class="capacity-text">Capacity:</p>
                <div class="capacity-meter">
                  <div class="filled-part"></div>
                </div>
                <h1 class="intel-big-text">Intel:</h1>
                <p class="tonight-text">Tonight:</p>
                <DynamicEventCard
                    class="event-card"
                    :name="$route.params.name"
                    district="district"
                    eventTitle="Lil Baby"
                    eventDate="July 19th"
                    eventTime="10pm"
                />
                <p class="last-updated-text">Last Updated: 9:30 pm</p>
                <p class="live-text">HISTORICAL TRENDS</p>
                <img class="trends-img" src="https://via.placeholder.com/100" alt="Image Spot" />
                
                <p class="name"> {{$route.params.name }}</p>
                <p class="district">District</p>
                <p class="address">29 NE 11th St, Miami, Florida, 33132</p>
                <p class="contact-text">(305) 829-2911</p>
                <p class="contact-text">11miami.com</p>
                <img class="location-img" src="https://via.placeholder.com/100" alt="Image Spot" />x
              </div>
  
              <div class="column">
                

                <p class="latest-text">Latest Video:</p>
                  <VideoCard class="video-card" :name="$route.params.name" eventTitle="Event 1" eventDate="Date 1" eventTime="Time 1"/>

                <p class="past-videos-text">PAST VIDEOS</p>

                <div class="row">
                  <VideoCard class="past-video-card" :name="$route.params.name" eventTitle="Event 2" eventDate="Date 2" eventTime="Time 1"/>
                  <VideoCard class="past-video-card" :name="$route.params.name" eventTitle="Event 3" eventDate="Date 3" eventTime="Time 1"/>
                </div>
                <p class="hours-text">Hours:</p>
                
                <div class="row">
                <pre class="days-text">
                    Monday
                    Tuesday
                    Wednesday
                    Thursday
                    Friday
                    Saturday
                    Sunday
                </pre>
                
                <pre class="open-text">
                    Open 24 Hours
                    Open 24 Hours
                    Open 24 Hours
                    Open 24 Hours
                    Open 24 Hours
                    Open 24 Hours
                    Open 24 Hours
                </pre>
                </div>

              </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>


  import VideoCard from '@/components/venues/VideoCard.vue';
  import DynamicEventCard from '@/components/venues/DynamicEventCard.vue'

  export default {
    name: 'DynamicVenuePage',
    components: {
    DynamicEventCard, // Register the EventCard component
    VideoCard,
  },
  };
  </script>
  
  <style>
  /* Set the height of the parent elements to 100% */
  html, body, #app {
    height: 100%;
  }
  
  .dynamic-home {
    background-color: #222;
    height: 1550px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  
  .dynamic-card-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }
  
  .dynamic-card {
    width: 900px;
    max-width: 2000px;
    height: 1500px;
    background-color: #434343;
    border-radius: 30px;
  }
  
  .dynamic-card-content {
    padding: 10px;
    text-align: left;
  }
  
  .row {
    display: flex;
  }
  
  .column {
    flex: 1;
    padding: 10px;
  }
  
  img {
    width: 30%;
    height: 9%;
  }

  .venue-img{
    width: 350px;
    height: 140px;
    border-radius: 30px;
    margin-left: 250px;
  }

  .location-img{
    width: 220px;
    height: 75px;
    border-radius: 30px;
  }

  .trends-img{
    width: 275px;
    height: 220px;
    border-radius: 30px;
  }

  
  .name{
    font-size: 40px;
    font-weight: bold;
    color: white;
    font-family: 'Vermin';
    margin-top: 10px;
  }

  .district{
    font-size: 24px;
    font-weight: bold;
    color: #B9B9B9;
    font-family: 'Vermin';
    margin-top: -35px;
  }
  .large-heading,
  .capacity-text,
  .last-updated-text {
    color: white;
    font-family: 'Cano';
  }
  
  .address {
    color: rgb(162, 162, 162);
    text-decoration: underline;
    font-size: 14px;
    font-family: 'Cano';  
    margin-top: -10px; 
  }

  .live-text {
    color: white;
    font-family: 'Cano';
    font-size: 25px;
    margin-top: 10px;
  }

  .capacity-text {
    color: white;
    font-family: 'Cano';
    font-size: 17px;
    margin-top: -15px;
    margin-left: 20px;
  }
  
  /* Capacity meter */
  .capacity-meter {
    width: 40%;
    height: 17px;
    background-color: lightgray;
    border-radius: 10px;
    margin-bottom: 10px;
    overflow: hidden;
    margin-left: 30px;
  }
  
  .filled-part {
    width: 55%; /* Adjust this value to change the filled percentage */
    height: 100%;
    background-color: rgb(255, 186, 57);
  }

  .intel-big-text {
    font-size: 17px;
    font-weight: bold;
    color: #BC8FF5;
    line-height: 10px;
    margin-top: 20px;
    margin-left: 20px;
  }

  .tonight-text {
    color: white;
    font-family: 'Cano';
    font-size: 17px;
    margin-left: 20px;
  }

  .event-card {
    margin-left: 20px;
  }

  .last-updated-text {
    color: white;
    font-family: 'Cano';
    font-size: 13px;
    margin-top: 15px;
    margin-left: 20px;
  }

  .hours-text {
    color: white;
    font-family: 'Cano';
    font-size: 20px;
    margin-top: 90px;
    margin-right: 430px;
  }

  .days-text {
    color: white;
    font-family: 'Cano';
    font-size: 19px;
    margin-right: 50px;
  }

  .pipes-text {
    color: white;
    font-family: 'Cano';
    font-size: 19px;
    margin-left: -90px;
    margin-top: -8px;
  }

  .open-text {
    color: rgb(144, 255, 100);
    font-family: 'Cano';
    font-size: 19px;
    margin-left: -90px;
  }

  .contact-text {
    color: rgb(188, 188, 188);
    font-family: 'Cano';
    font-size: 13px;
  }

  .small-name-text {
    color: white;
    font-family: 'Vermin';
    font-size: 25px;
    margin-left: -290px;
  }
  
  .tonight-text-otro {
    color: white;
    font-family: 'Cano';
    font-size: 17px;
    margin-left: -290px;
    margin-top: -5px;
  }

  .event-text {
    color: white;
    font-family: 'Vermin';
    font-size: 25px;
    margin-top: -5px;
    margin-left: -290px;
  }

  .video-icon-link {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    background-color: #ffffff;
    border-radius: 5px;
    margin-left: -130px;
    margin-top: -120px;
    }

   .video-icon {
        width: 70%;
        height: 70%;
    }

  .video-card {
    margin-top: 20px;
    margin-left: 20px;
  }

  .past-video-card {
    margin-top: 20px;
    margin-left: 20px;
  }

  .past-videos-text {
    color: white;
    font-family: 'Cano';
    font-size: 25px;
    margin-top: 162px;
  }
  .latest-text {
    color: white;
    font-family: 'Cano';
    font-size: 20px;
    margin-top: 30px;
  }

  .description-text {
    color: white;
    font-family: 'Cano';
    font-size: 13px;
    margin-top: 15px;
    margin-left: 20px;
    padding-bottom: 50px;
  }

  .grid-row {
    display: flex;
    flex: 1;
  }
  
  .grid-column {
    display: column;
  }

  @media (max-width: 767px) {
    .dynamic-home {
    background-color: #222;
    height: 1150px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  
  .dynamic-card-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }
  
  .dynamic-card {
    width: 360px;
    max-width: 2000px;
    height: 1100px;
    background-color: #434343;
    border-radius: 30px;
  }
  
  .dynamic-card-content {
    padding: 10px;
    text-align: left;
  }
  
  .row {
    display: flex;
  }
  
  .column {
    flex: 1;
    padding: 10px;
  }
  
  img {
    width: 30%;
    height: 9%;
  }

  .venue-img{
    width: 140px;
    height: 56px;
    border-radius: 30px;
    margin-left: 80px;
  }

  .location-img{
    width: 100px;
    height: 40px;
    border-radius: 30px;
    margin-left: 20px;
  }

  .trends-img{
    width: 150px;
    height: 100px;
    border-radius: 30px;
    margin-left: 10px
  }

  
  .name{
    font-size: 16px;
    font-weight: bold;
    color: white;
    font-family: 'Vermin';
    margin-top: 175px;
    margin-left: 20px;
  }

  .district{
    font-size: 10px;
    font-weight: bold;
    color: #B9B9B9;
    font-family: 'Vermin';
    margin-top: -15px;
    margin-left: 20px;
  }
  .large-heading,
  .capacity-text,
  .tonight-text,
  .last-updated-text {
    color: white;
    font-family: 'Cano';
    font-size: 24px;
  }
  
  .address {
    color: rgb(162, 162, 162);
    text-decoration: underline;
    font-size: 9px;
    font-family: 'Cano';  
    margin-top: -10px; 
    margin-left: 20px;
  }

  .live-text {
    color: white;
    font-family: 'Cano';
    font-size: 14px;
    margin-top: 10px;
    margin-left: 15px;
  }

  .capacity-text {
    color: white;
    font-family: 'Cano';
    font-size: 10px;
    margin-top: -5px;
    margin-left: 20px;
  }
  
  /* Capacity meter */
  .capacity-meter {
    width: 30%;
    height: 6px;
    background-color: lightgray;
    border-radius: 10px;
    margin-bottom: 10px;
    overflow: hidden;
    margin-left: 30px;
  }
  
  .filled-part {
    width: 55%; /* Adjust this value to change the filled percentage */
    height: 100%;
    background-color: rgb(255, 186, 57);
  }

  .intel-big-text {
    font-size: 10px;
    font-weight: bold;
    color: #BC8FF5;
    line-height: 10px;
    margin-top: 25px;
    margin-left: 20px;
  }

  .tonight-text {
    color: white;
    font-family: 'Cano';
    font-size: 8px;
    margin-left: 20px;
    margin-top: 20px;
  }

  .event-card {
    margin-left: 20px;
  }

  .last-updated-text {
    color: white;
    font-family: 'Cano';
    font-size: 8px;
    margin-top: 15px;
    margin-left: 20px;
  }

  .hours-text {
    display: none;
  }

  .days-text {
    color: white;
    font-family: 'Cano';
    font-size: 9px;
    margin-left: -90px;
    margin-top: 50px;
    width: 100px;
  }

  .pipes-text {
    color: white;
    font-family: 'Cano';
    font-size: 19px;
    margin-left: -90px;
    margin-top: -8px;
    width: 100px;
  }

  .open-text {
    color: rgb(144, 255, 100);
    font-family: 'Cano';
    font-size: 9px;
    margin-left: -90px;
    margin-top: 50px;
    width: 100px;
  }

  .contact-text {
    color: rgb(188, 188, 188);
    font-family: 'Cano';
    font-size: 9px;
    margin-left: 20px;
  }

  .small-name-text {
    color: white;
    font-family: 'Vermin';
    font-size: 10px;
    margin-left: -290px;
  }
  
  .tonight-text-otro {
    color: white;
    font-family: 'Cano';
    font-size: 17px;
    margin-left: -290px;
    margin-top: -5px;
  }

  .event-text {
    color: white;
    font-family: 'Vermin';
    font-size: 25px;
    margin-top: -5px;
    margin-left: -290px;
  }

  .video-icon-link {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    background-color: #ffffff;
    border-radius: 5px;
    margin-left: -130px;
    margin-top: -120px;
    }

   .video-icon {
        width: 70%;
        height: 70%;
    }

  .video-card {
    margin-top: 10px;
    margin-left: -120px;
  }

  .past-video-card {
    margin-top: 10px;
    margin-left: -220px;
  }

  .past-videos-text {
    color: white;
    font-family: 'Cano';
    font-size: 14px;
    margin-top: 290px;
    margin-left: -217px;
  }
  .latest-text {
    color: white;
    font-family: 'Cano';
    font-size: 10px;
    margin-top: 12px;
    margin-left: -130px;
  }

  .description-text {
    color: white;
    font-family: 'Cano';
    font-size: 7px;
    margin-top: 15px;
    margin-left: 20px;
    padding-bottom: 20px;
  }

  .grid-row .grid-column:not(:first-child) .event-card {
    display: none;
  }

  .row .past-video-card:nth-child(2) {
  display: none;
  } 
  }
  </style>
  