<template>
  <router-link :to="getLinkUrl(id, name, city)" class="card">
    <div class="card-container">
      <div class="card-content">
        <div class="card-details">
          <h2 :class="['card-name', fontSizeClass]">{{ name }}</h2>
          <p class="card-district">{{ district }}</p>
          <p class="card-info card-hours">{{ randomHours }}</p>
          <p class="card-capacity-text">Busyness:</p>
          <div class="card-progress-bar">
            <div class="progress-bar">
              <div class="progress-bar-fill" :style="{ width: progressBarWidth, backgroundColor: progressBarColor(progressBarWidth) }"></div>
            </div>
          </div>
          <p class="intel-text">{{ randomIntel }}</p>
          <div class="icon-column">
            <a class="icon-link" href="link1">
              <img src="@/assets/icons/video.png" class="icon" alt="Icon 1" />
            </a>
            <a class="icon-link" href="link2">
              <img src="@/assets/icons/ticket.png" class="icon" alt="Icon 2" />
            </a>
          </div>
          <p class="tonight">Tonight at 9pm:</p>
          <p class="artist">Rick Ross</p>
          <p class="updated">Last Updated: 1:30 am</p>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  name: 'LiveCard',
  props: {
    name: {
      type: String,
      required: true,
    },
    district: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    city: {
      type: String,
      required: true,
    },
  },
  computed: {
    fontSizeClass() {
      const nameLength = this.name.length;
      if (nameLength > 23) {
        return 'card-name-superdupersmall';
      } else if (nameLength > 21) {
        return 'card-name-supersmall';
      } else if (nameLength > 19) {
        return 'card-name-smallest';
      } else if (nameLength > 14) {
        return 'card-name-kindasmaller';
      } else if (nameLength > 12) {
        return 'card-name-smaller';
      } else if (nameLength > 10) {
        return 'card-name-small';
      } else if (nameLength > 8) {
        return 'card-name-kindasmall';
      } else {
        return 'card-name-regular';
      }
    },
    randomHours() {
      const hoursOptions = ['Open 24 Hours', 'Open until Midnight', 'Open until 2:00 AM', 'Open until 4:00 AM', 'Open until 6:00 AM', 'Closed'];
      const randomIndex = Math.floor(Math.random() * hoursOptions.length);
      return hoursOptions[randomIndex];
    },
    progressBarWidth() {
      const progressOptions = ['10%', '30%', '50%', '70%', '90%'];
      const randomIndex = Math.floor(Math.random() * progressOptions.length);
      return progressOptions[randomIndex];
    },
    randomIntel() {
      const intelOptions = ['20s-30s, packed, long line', 'almost empty, no line', 'long line, unders getting denied', '20s-40s, needs more men', 'average night, no line'];
      const randomIndex = Math.floor(Math.random() * intelOptions.length);
      return `Intel: ${intelOptions[randomIndex]}`;
    },
  },
  methods: {
    progressBarColor(width) {
      if (width === '10%') {
        return '#84f584'; // Green
      } else if (width === '30%') {
        return '#bae26a'; // Light green
      } else if (width === '50%') {
        return '#f9ec36'; // Yellow
      } else if (width === '70%') {
        return 'f88800'; // Orange
      } else if (width === '90%') {
        return '#f13737'; // Red
      }
      return '#c1c1c1'; // Default color if no match found
    },
    getLinkUrl(id, name, city) {
      return { name: 'DynamicVenuePage', params: { id, name, city} };
    },
  },
};
</script>

<style scoped>
.card {
  width: 210px;
  height: 230px;
  background-color: #373030;
  border-radius: 15px;
  transition: border-color 0.8s ease, box-shadow 0.8s ease;
}


.card-container {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  font-family: 'Bebas';
}

.card-content {
  padding: 10px;
  text-align: left;
}

.card-details {
  color: #fff;
}

.card-name {
  position: absolute;
  width: 250px;
  font-family: 'Vermin';
  color: #FFFFFF;
}

.card-name-kindasmall {
  font-size: 25px;
  top: 7px;
}

.card-name-small {
  font-size: 20px;
  top: 16px;
}

.card-name-smaller {
  font-size: 18px;
  top: 20px;
}

.card-name-kindasmaller {
  font-size: 15px;
  top: 23px;
}

.card-name-smallest {
  font-size: 14px;
  top: 30px;
}

.card-name-supersmall {
  font-size: 12px;
  top: 35px;
}

.card-name-superdupersmall {
  font-size: 9px;
  top: 40px;
}

.card-name-regular {
  font-size: 30px;
  top: 0px;
}

.card-district {
  position: absolute;
  top: 45px;
  width: 189px;
  height: 15px;
  font-family: 'Vermin';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: #B9B9B9;
}

.card-info {
  font-family: 'Cano';
  position: absolute;
  top: 60px;
  font-size: 16px;
}

.closing-never {
  color: rgb(132, 245, 132);
}

.closing-soon {
  color: rgb(186, 226, 106);
}

.closing-kindasoon {
  color: rgb(249, 236, 54);
}

.closing-sooner {
  color: rgb(248, 173, 68);
}

.closing-soonest {
  color: rgb(255, 126, 117);
}

.closing-closed {
  color: rgb(241, 55, 55);
}

.card-capacity-text {
  position: absolute;
  font-family: 'Cano';
  font-size: 12px;
  color: white;
  top: 85px;
}

.card-progress-bar {
  display: flex;
  align-items: left;
}

.progress-bar {
  position: absolute;
  top: 120px;
  width: 170px;
  height: 8px;
  background-color: #c1c1c1;
  border-radius: 5px;
  margin-bottom: 3px;
}

.progress-bar-fill {
  width: 50%;
  height: 100%;
  background-color: rgb(250, 195, 32);
  border-radius: 5px;
}

.green-progress-bar-fill {
  background-color: #84f584;
}

.light-green-progress-bar-fill {
  background-color: #bae26a;
}

.yellow-progress-bar-fill {
  background-color: #f9ec36;
}

.orange-progress-bar-fill {
  background-color: #f88800;
}

.red-progress-bar-fill {
  background-color: #f13737;
}

.intel-text {
  font-family: 'Cano';
  color: #BC8FF5;
  font-size: 11px;
  position: absolute;
  top: 125px;
  left: 12px;
}

.image-container {
  display: flex;
  justify-content: left;
  gap: 7px;
  margin-top: 150px;
  position: absolute;
}

.image-link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px
}

.lyft {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.uber {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.google {
  width: 70%;
  height: 100%;
  border-radius: 5px;
}

.tonight {
  font-size: 12px;
  font-family: 'Cano';
  color: #b3b3b3;
  position: absolute;
  top: 145px;
  left: 105px;
}

.artist {
  font-size: 17px;
  font-family: 'Cano';
  font-weight: bold;
  color: #ffffff;
  position: absolute;
  top: 160px;
  left: 110px;
}

.desc {
  font-size: 12px;
  font-family: 'Cano';
  color: #b3b3b3;
  position: absolute;
  top: 210px;
  left: 12px;
}

.updated {
  font-size: 11px;
  font-family: 'Cano';
  color: #ffffff;
  position: absolute;
  top: 200px;
  left: 13px;
}

.icon-column {
  display: flex;
  flex-direction: row;
  gap: 5px;
  position: absolute;
  top: 160px;
  left: 12px;
}

.icon-link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: #ffffff;
  border-radius: 5px;
}

.icon {
  width: 70%;
  height: 70%;
}

.card:hover {
  border-color: #fff;
  box-shadow: 0 0 4px 4px rgba(255, 255, 255, 0.2);
}

@media (max-width: 767px) {
  .card {
    width: 158px;
    height: 173px;
  }
  .card-name {
  position: absolute;
  width: 250px;
  font-family: 'Vermin';
  color: #FFFFFF;
  }

  .card-name-kindasmall {
    font-size: 19px;
    top: 7px;
  }

  .card-name-small {
    font-size: 15px;
    top: 16px;
  }

  .card-name-smaller {
    font-size: 14px;
    top: 20px;
  }

  .card-name-kindasmaller {
    font-size: 11px;
    top: 23px;
  }

  .card-name-smallest {
    font-size: 10px;
    top: 30px;
  }

  .card-name-supersmall {
    font-size: 9px;
    top: 35px;
  }

  .card-name-superdupersmall {
    font-size: 7px;
    top: 40px;
  }

  .card-name-regular {
    font-size: 23px;
    top: 0px;
  }

  .card-district {
    top: 33px;
    font-size: 9px;
  }

  .card-info {
    top: 46px;
    font-size: 12px;
  }

  .card-capacity-text {
    top: 63px;
    font-size: 11px;
  }

  .progress-bar {
    top: 93px;
    width: 128px;
    height: 6px;
  }

  .intel-text {
    top: 98px;
    font-size: 8px;
  }

  .tonight {
    top: 112px;
    font-size: 9px;
    width: 70px;
    left: 85px;
  }

  .artist {
    top: 123px;
    font-size: 13px;
    width: 70px;
    left: 90px;
  }

  .desc {
    top: 190px;
    font-size: 9px;
  }

  .updated {
    top: 150px;
    font-size: 8px;
  }

  .icon-column {
    top: 123px;
  }

  .icon-link {
    top: 140px;
    width: 30px;
    height: 30px;
  }

}
</style>
