<template>
  <div class="card">
    <div class="card-container">
      <img class="card-image" src="path/to/image.jpg" alt=""/>
      <div class="card-content">
        <div class="name-district-container">
          <h2 :class="['card-name', fontSizeClass]">{{ name }}</h2>
          <h3 class="card-district">{{ district }}</h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VenueCard',
  props: {
    name: {
      type: String,
      required: true,
    },
    district: {
      type: String,
      required: true,
    },
  },
  computed: {
    fontSizeClass() {
      const nameLength = this.name.length;
      if (nameLength > 19) {
        return 'card-name-smallest';
      } else if (nameLength > 14) {
        return 'card-name-kindasmaller';
      } else if (nameLength > 12) {
        return 'card-name-smaller';
      } else if (nameLength > 10) {
        return 'card-name-small';
      } else if (nameLength > 8) {
        return 'card-name-kindasmall';
      } else {
        return 'card-name-regular';
      }
    },
  },
};
</script>

<style scoped>
.card {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 225px;
  height: 125px;
  background-color: #373030;
  border-radius: 15px;
  font-family: 'Bebas', sans-serif;
}

.card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: relative;
}

.card-image {
  width: 150px;
  height: 50px;
  top: 10px;
  position: absolute;
  background-image: #d2bcbc;
  background-color: #434343;
  border-radius: 10px;
}

.card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.name-district-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.card-name {
  font-family: 'Vermin';
  color: #FFFFFF;
  width: 200px;
  text-align: center;
  margin-top: 70px; /* Adjust the value to move the name up or down */
}

.card-name-kindasmall {
  font-size: 25px;
}

.card-name-small {
  font-size: 20px;
}

.card-name-smaller {
  font-size: 18px;
}

.card-name-kindasmaller {
  font-size: 15px;
}

.card-name-smallest {
  font-size: 14px;
}

.card-name-regular {
  font-size: 30px;
}

.card-district {
  font-family: 'Vermin';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: #B9B9B9;
  text-align: center;
  position: absolute;
  bottom: -5px; /* Adjust the value to move the district up or down */
  width: 100%;
}

@media (max-width: 767px) {
  .card {
  width: 113px;
  height: 63px;
}


.card-image {
  width: 75px;
  height: 25px;
  top: 10px;
}


.card-name {
  margin-top: 40px; /* Adjust the value to move the name up or down */
}

.card-name-kindasmall {
  font-size: 13px;
}

.card-name-small {
  font-size: 10px;
}

.card-name-smaller {
  font-size: 9px;
}

.card-name-kindasmaller {
  font-size: 8px;
}

.card-name-smallest {
  font-size: 7px;
}

.card-name-regular {
  font-size: 15px;
}

.card-district {
  font-family: 'Vermin';
  font-style: normal;
  font-weight: 400;
  font-size: 6px;
  line-height: 17px;
  color: #B9B9B9;
  text-align: center;
  position: absolute;
  top: 45px; /* Adjust the value to move the district up or down */
  width: 100%;
}

}
</style>
