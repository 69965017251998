<template>
  <div id="page">
    <img :src="currentGif" alt="GIF" />
    <div class="gif-text">Find your nightlife destinations here</div>
  </div>
</template>

<script>
export default {
  name: 'Gif',
  data() {
    return {
      gifs: [
        require('@/assets/gifs/compressed.gif'),
        // require('@/assets/gifs/gif2.gif'),
        // require('@/assets/gifs/gif3.gif'),
        // require('@/assets/gifs/gif4.gif'),
        // require('@/assets/gifs/gif5.gif'),
        // require('@/assets/gifs/gif6.gif'),
        // require('@/assets/gifs/gif7.gif'),
        // require('@/assets/gifs/gif8.gif'),
        // require('@/assets/gifs/gif9.gif'),
      ],
      activeIndex: 0,
    };
  },
  mounted() {
    setInterval(() => {
      this.activeIndex = (this.activeIndex + 1) % this.gifs.length;
    }, 20000);
  },
  computed: {
    currentGif() {
      return this.gifs[this.activeIndex];
    },
  },
};
</script>

<style scoped>
#page {
  scroll-snap-align: start;
  scroll-snap-stop: always;
  position: relative;
  width: 100%;
  height: 622px;
  padding-top: 0px; /* Adjust the padding value as needed */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.gif-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-family: 'Bebas';
  font-size: 50px;
  z-index: 1;
}

#page img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
