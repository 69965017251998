<template>
    <div class="page-container">
      <ComingSoonGif/>
    </div>
  </template>
      
<script>
  
      import ComingSoonGif from '@/components/about/ComingSoonGif.vue';
      export default {
      name: 'AboutPage',
      components: {
        ComingSoonGif,
      },
      
     };
</script>
      
      <style scoped>
      .page-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: top;
        padding: 0px;
        background-color: #222222;
      }
      </style>
      