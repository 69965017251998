<template>
    <div class="live">
      <LivePage/>
    </div>
  </template>
  
  <script>
  import LivePage from '@/components/live/LivePage.vue';
  
  export default {
    name: 'LiveView',
    components: {
      LivePage,
    }
  };
  </script>
  
  <style>
  .home {
    height: 100vh;
    overflow-y: auto;
  }
  </style>
  