<template>
    <div id="page">
      <img :src="currentGif" alt="GIF" />
      <div class="gif-text">Coming soon.</div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ComingSoonGif',
    data() {
      return {
        gifs: [
          require('@/assets/gifs/gif5.gif'),
        ],
        activeIndex: 0,
      };
    },
    mounted() {
      setInterval(() => {
        this.activeIndex = (this.activeIndex + 1) % this.gifs.length;
      }, 20000);
    },
    computed: {
      currentGif() {
        return this.gifs[this.activeIndex];
      },
    },
  };
  </script>
  
  <style scoped>
  #page {
    scroll-snap-align: start;
    scroll-snap-stop: always;
    position: relative;
    width: 100%;
    height: 757px;
    padding-top: 0px; /* Adjust the padding value as needed */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .gif-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-family: 'Bebas';
    font-size: 50px;
    z-index: 1;
  }
  
  #page img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  </style>
  