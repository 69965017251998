<template>
  <div class="home-page">
    <Gif />
    <!-- <HomePage /> -->
  </div>
</template>

<script>
import Gif from '@/components/home/Gif.vue';
import HomePage from '@/components/home/HomePage.vue';

export default {
  name: 'HomeView',
  components: {
    Gif,
    HomePage,
  }
};
</script>

<style>

body {
  margin: 0;
  padding: 0;
}

.home-page {
  max-width: 100%;
  background-color: #222;
}

</style>
