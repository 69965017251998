<template>
  <div class="page-container">
    <div class="city-links">
      <a
        v-for="(city, index) in cities"
        :key="index"
        :class="['city-link', { 'selected': city === selectedCity }]"
        @click="updateCarousel(city)"
      >
        {{ city.name }}
      </a>
    </div>
    <div class="card-grid">
      <VenueCard v-for="card in cards" :id="card.id" :name="card.name" :district="card.district" :city="card.city" />
    </div>
  </div>
</template>
  
  <script>
  import VenueCard from './VenueCard.vue';
  import { initializeApp } from 'firebase/app';
  import { getDatabase, ref, onValue } from 'firebase/database';
  
  const firebaseConfig = {
      apiKey: "AIzaSyAjnz10Wj_4hHbQ0js-OE4U0uy43o2iyE0",
      authDomain: "nightflow-d9652.firebaseapp.com",
      databaseURL: "https://nightflow-d9652-default-rtdb.firebaseio.com",
      projectId: "nightflow-d9652",
      storageBucket: "nightflow-d9652.appspot.com",
      messagingSenderId: "731395358560",
      appId: "1:731395358560:web:15e072c56f03736cdd6805",
      measurementId: "G-NGBBYV6S6Q"
  };
  
  const firebaseApp = initializeApp(firebaseConfig);
  const firebaseDb = getDatabase(firebaseApp);
  
  export default {
  name: 'VenuesPage',
  components: {
    VenueCard,
  },
  data() {
    return {
      cities: [
        { id: 1, name: 'Miami' },
        { id: 2, name: 'Boston' },
      ],
      selectedCity: null,
      cards: [],
    };
  },
  created() {
    this.fetchCardsFromFirebase();
  },
  methods: {
    updateCarousel(city) {
      this.selectedCity = city;
      this.fetchCardsFromFirebase(city.name.toLowerCase());
    },
    fetchCardsFromFirebase(cityName = 'miami') {
      const cardsRef = ref(firebaseDb, `cities/${cityName}`);
      onValue(cardsRef, (snapshot) => {
        const cardsData = snapshot.val();
        const cards = [];

        for (const key in cardsData) {
          if (cardsData.hasOwnProperty(key)) {
            const card = {
              id: key,
              name: cardsData[key].name,
              district: cardsData[key].district,
              address: cardsData[key].address,
              type: cardsData[key].type,
              city: cityName,
            };

            cards.push(card);
          }
        }

        this.cards = cards;
      });
    },
  },
};
</script>
  
  <style scoped>
  .page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: top;
    padding: 0px;
    background-color: #222222;
}

  
  .city-links {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    padding-top: 20px;
  }
  
  .city-link {
    margin: 0 10px;
    color: white;
    font-family: 'Bebas', sans-serif; /* Replace with your desired font */
    font-size: 35px;
    cursor: pointer;
    transition: color 0.4s;
  }
  
  .city-link.selected {
    color: rgb(168, 68, 239);
  }
  
  
  .card-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    flex-grow: 1;
    margin-bottom: 20px;
}

  </style>
  