<template>
  <nav class="navbar">
    <div class="navbar-links">
      <div class="navbar-links-left">
        <li v-for="(link, index) in leftLinks" :key="index">
          <a :href="link.url" class="navbar-link">{{ link.label }}</a>
        </li>
      </div>
      
      <div class="navbar-logo">
        <a href="/" class="navbar-logo-link">
          <img src="@/assets/logos/logo.png" alt="Logo" />
        </a>
      </div>

      <div class="navbar-links-right">
        <li v-for="(link, index) in rightLinks" :key="index">
          <a :href="link.url" class="navbar-link">{{ link.label }}</a>
        </li>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'Navbar',
  data() { 
    return {
      leftLinks: [
        { label: 'ABOUT', url: '/about' },
        { label: 'VENUES', url: '/venues' },
      ],
      rightLinks: [
        { label: 'LIVE', url: '/live' },
        { label: 'EVENTS', url: '/events' },
      ],
    };
  }
};
</script>

<style scoped>
body {
  margin: 0;
  padding: 0;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #222222;
  padding: 50px;
  position: relative;
}

.navbar-logo {
  position: relative;
  flex-grow: 2;
}

.navbar-logo img {
  width: 140px;
  height: 140px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.navbar-links {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.navbar-links-left, .navbar-links-right {
  display: flex;
  flex-grow: 1;
  justify-content: space-evenly;
}

.navbar-links li {
  list-style: none;
}

.navbar-link {
  font-family: 'Bebas', sans-serif;
  font-size: 40px;
  color: white;
  text-decoration: none;
  transition: color 0.8s;
}

.navbar-link:hover {
  color: rgb(168, 68, 239);
}

/* This will make the font size even smaller for screens less than 768px */
@media (max-width: 768px) {
  .navbar-link {
    font-size: 30px;
  }

  .navbar-logo img {
  width: 90px;
  height: 90px;
}
}

/* This will make the font size smallest for screens less than 600px */
@media (max-width: 600px) {
  .navbar-link {
    font-size: 20px;
  }
  .navbar-logo img {
  width: 70px;
  height: 70px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
}
</style>
