<template>
    <div class="home">
      <EventsPage/>
    </div>
  </template>
  
  <script>
  import EventsPage from '@/components/events/EventsPage.vue';
  
  export default {
    name: 'EventsView',
    components: {
      EventsPage,
    }
  };
  </script>
  
  <style>
  .home {
    height: 100%;
    overflow-y: auto;
  }
  </style>
  