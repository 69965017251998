<template>
    <div class="card">
      <div class="card-container">
        <!-- <img class="card-image" :src="venueimg" /> -->
        
        <p :class="['card-name', fontSizeClass]">{{ name }}</p>
        <p class="card-event">{{ eventDate }}</p>
        <p class="card-title">{{ eventTitle }}</p>
        <a class="icon-link" href="link1">
          <img src="@/assets/icons/video.png" class="icon" alt="Icon 1" />
        </a>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'VideoCard',
    props: {
      name: {
        type: String,
        required: true,
      },
      eventTitle: {
        type: String,
        required: true,
      },
      eventDate: {
        type: String,
        required: true,
      },
    },
    computed: {
      fontSizeClass() {
        const nameLength = this.name.length;
        if (nameLength > 19) {
          return 'card-name-smallest';
        } else if (nameLength > 14) {
          return 'card-name-kindasmaller';
        } else if (nameLength > 12) {
          return 'card-name-smaller';
        } else if (nameLength > 10) {
          return 'card-name-small';
        } else if (nameLength > 8) {
          return 'card-name-kindasmall';
        } else {
          return 'card-name-regular';
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .card {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 230px;
    height: 130px;
    background-color: #373030;
    border-radius: 15px;
    font-family: 'Bebas', sans-serif;
  }
  
  .card-container {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    font-family: 'Bebas';
    }
  
  .card-name {
    font-family: 'Vermin';
    color: #FFFFFF;
    font-size: 32px;
    position: absolute;
    left: 10px;
    line-height: 10px;
  }
  
  
  .card-event {
    font-family: 'Vermin';
    color: #ababab;
    font-size: 13px;
    position: absolute;
    top: 50px;
    left: 10px;
  }
  
  .card-title {
    font-family: 'Vermin';
    color: #FFFFFF;
    font-weight: bold;
    font-size: 30px;
    position: absolute;
    top: 50px;
    left: 10px;
  }
  

  .icon-link {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px; /* Width of the white box */
    height: 50px; /* Height of the white box */
    background-color: #ffffff;
    border-radius: 5px;
    top: 70px;
    left: 170px;
  }

  .icon {
    width: 70%; /* Adjust the percentage as needed to resize the icon */
    height: 70%; /* Adjust the percentage as needed to resize the icon */
  }

  @media (max-width: 767px) {
  .card {
    width: 195px;
    height: 98px;
  }

  .card-name {
    font-size: 24px;
    left: 10px;
    line-height: 10px;
  }
  
  
  .card-event {
    font-size: 10px;;
    top: 40px;
    left: 10px;
  }
  
  .card-title {
    font-size: 25px;
    top: 40px;
    left: 10px;
  }
  

  .icon-link {
    width: 38px; /* Width of the white box */
    height: 38px; /* Height of the white box */
    top: 55px;
    left: 145px;
  }

}
  </style>
  