<template>
  <div class="home">
    <AboutPage/>
    <!-- <HomePage /> -->
  </div>
</template>

<script>
import AboutPage from '@/components/about/AboutPage.vue';

export default {
  name: 'AboutView',
  components: {
    AboutPage,
  }
};
</script>

<style>

body {
  margin: 0;
  padding: 0;
}

.home {
  max-width: 100%;
  background-color: #222;
}

</style>
