<template>
  <div id="app">
    <Navbar
      :logoSrc="logoSrc"
      :logoAlt="logoAlt"
      :leftLinks="leftLinks"
      :rightLinks="rightLinks"
    />
    <router-view/>
    <Footer/>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue';
import Footer from '@/components/Footer.vue';

export default {
  name: 'App',
  components: {
    Navbar,
    Footer
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}


nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

@font-face {
    font-family: 'Bebas';
    src: url('@/assets/fonts/BebasNeue-Regular.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Vermin';
    src: url('@/assets/fonts/VV2NC.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Cano';
    src: url('@/assets/fonts/Cano.ttf') format('truetype');
  }
</style>
