<template>
  <div class="page-container">
    <div class="city-links">
      <a
        v-for="(city, index) in cities"
        :key="index"
        :class="['city-link', { 'selected': city === selectedCity }]"
        @click="updateCarousel(city)"
      >
        {{ city.name }}
      </a>
    </div>

    <div class="card-grid">
      <LiveCard v-for="card in cards" :id="card.id" :name="card.name" :district="card.district" :city="card.city" />
    </div>
  </div>
</template>

<script>
import LiveCard from './LiveCard.vue';
import { initializeApp } from 'firebase/app';
import { getDatabase, ref, onValue } from 'firebase/database';

const firebaseConfig = {
  apiKey: "AIzaSyAjnz10Wj_4hHbQ0js-OE4U0uy43o2iyE0",
  authDomain: "nightflow-d9652.firebaseapp.com",
  databaseURL: "https://nightflow-d9652-default-rtdb.firebaseio.com",
  projectId: "nightflow-d9652",
  storageBucket: "nightflow-d9652.appspot.com",
  messagingSenderId: "731395358560",
  appId: "1:731395358560:web:15e072c56f03736cdd6805",
  measurementId: "G-NGBBYV6S6Q"
};

const firebaseApp = initializeApp(firebaseConfig);
const firebaseDb = getDatabase(firebaseApp);

export default {
  name: 'LivePage',
  components: {
    LiveCard,
  },
  data() {
    return {
      cities: [
        { id: 1, name: 'Miami' },
        { id: 2, name: 'Boston' },
      ],
      selectedCity: null,
      cards: [],
    };
  },
  created() {
    this.fetchCardsFromFirebase();
  },
  methods: {
    updateCarousel(city) {
      this.selectedCity = city;
      this.fetchCardsFromFirebase(city.name.toLowerCase());
    },
    fetchCardsFromFirebase(cityName = 'miami') {
      const cardsRef = ref(firebaseDb, `cities/${cityName}`);
      onValue(cardsRef, (snapshot) => {
        const cardsData = snapshot.val();
        const cards = [];

        for (const key in cardsData) {
          if (cardsData.hasOwnProperty(key)) {
            const card = {
              city: cityName,
              id: key,
              name: cardsData[key].name,
              district: cardsData[key].district,
              address: cardsData[key].address,
              type: cardsData[key].type,
            };

            cards.push(card);
          }
        }

        this.cards = cards;
      });
    },
  },
};
</script>

<style scoped>
.page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  padding: 0px;
  background-color: #222222;
  overflow-x: hidden; /* Add this line */
}


.city-links {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; /* Add this line */
  margin-bottom: 20px;
  padding-top: 20px;
  margin-left: 19px;;
}

.city-link {
  margin: 0 10px;
  color: white;
  font-family: 'Bebas', sans-serif;
  font-size: 35px;
  cursor: pointer;
  transition: color 0.4s;
  display: flex; /* Add this line */
  align-items: center; /* Add this line */
}

.city-link.selected {
  color: rgb(168, 68, 239);
}

.search-bar {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  border-radius: 20px;
  overflow: hidden;
  border-color: rgb(168, 68, 239);
}

.search-bar input {
  padding: 10px;
  margin-right: 10px;
  border: none;
  border-radius: 20px;
}

.search-bar button {
  padding: 10px 20px;
  background-color: rgb(168, 68, 239);
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
}

.search-bar button:hover {
  background-color: #9933cc;
}

.search-dropdown {
  padding: 10px;
  border: none;
  border-radius: 20px;
  margin-right: 10px;
  margin-left: 10px;
  background-color: rgb(168, 68, 239);
  color: white;
}

.card-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    flex-grow: 1;
    margin-bottom: 20px;
}
</style>




