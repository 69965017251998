//Import vue-router
import { createRouter, createWebHistory } from 'vue-router'

//Import views
import HomeView from '../views/HomeView.vue'
import VenuesView from '../views/VenuesView.vue'
import LiveView from '../views/LiveView.vue'
import EventsView from '../views/EventsView.vue'
import AboutView from '../views/AboutView.vue'
import DNEPage from '@/components/DNEPage.vue';
import DynamicVenuePage from '@/components/venues/DynamicVenuePage.vue';

//Routes
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/venues',
    name: 'venues',
    component: VenuesView
  },
  {
    path: '/events',
    name: 'events',
    component: EventsView
  },
  {
    path: '/live',
    name: 'live',
    component: LiveView
  },

  {
    path: '/about',
    name: 'about',
    component: AboutView
  },
  {
    path: '/:pathMatch(.*)',
    name: 'not-found',
    component: DNEPage
  },
  {
    path: '/venues/:city/:name', // Define the route path with the parameter name
    name: 'DynamicVenuePage', // Assign a name to the route
    component: DynamicVenuePage, // Specify the component to render
  },
]

//Router that links to domain
const router = createRouter({
  base: 'https://nightsync.live',
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
