<template>
    <div class="venues">
      <VenuesPage/>
    </div>
  </template>
  
  <script>
  import VenuesPage from '@/components/venues/VenuesPage.vue';
  
  export default {
    name: 'VenuesView',
    components: {
      VenuesPage,
    }
  };
  </script>
  
  <style>
  .home {
    height: 100vh;
    overflow-y: auto;
  }
  </style>
  