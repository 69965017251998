<template>
    <div class="page-container">
      <div class="city-links">
        <a
          v-for="(city, index) in cities"
          :key="index"
          :class="['city-link', { 'selected': city === selectedCity }]"
          @click="updateCarousel(city)"
        >
          {{ city.name }}
        </a>
      </div>
      <div class="card-grid">
        <EventCard
          v-for="card in cards"
          :key="card.id"
          :name="card.name"
          :district="card.district"
          :venueimg="card.venueimg"
          :eventimg="card.eventimg"
          :eventTitle="card.eventTitle"
          :eventDate="card.eventDate"
          :eventTime="card.eventTime"
        />
      </div>
    </div>
  </template>
  
  <script>
  import EventCard from './EventCard.vue';
  
  export default {
    name: 'EventsPage',
    components: {
      EventCard,
    },
    data() {
      return {
        cities: [
          { name: 'Miami' },
          { name: 'Boston' },
          // Add more cities as needed
        ],
        selectedCity: null,
        cards: [
          {
            id: 1,
            name: 'E11even',
            district: 'Brickell',
            venueimg: require('@/assets/images/eleven.png'),
            eventimg: require('@/assets/images/lilbaby.png'),
            eventTitle: 'Lil Baby',
            eventDate: 'July 27th',
            eventTime: '10:00pm',
          },
          {
            id: 1,
            name: 'E11even',
            district: 'Brickell',
            venueimg: require('@/assets/images/eleven.png'),
            eventimg: require('@/assets/images/lilbaby.png'),
            eventTitle: 'Lil Baby',
            eventDate: 'July 27th',
            eventTime: '10:00pm',
          },
          {
            id: 1,
            name: 'E11even',
            district: 'Brickell',
            venueimg: require('@/assets/images/eleven.png'),
            eventimg: require('@/assets/images/lilbaby.png'),
            eventTitle: 'Lil Baby',
            eventDate: 'July 27th',
            eventTime: '10:00pm',
          },
          {
            id: 1,
            name: 'E11even',
            district: 'Brickell',
            venueimg: require('@/assets/images/eleven.png'),
            eventimg: require('@/assets/images/lilbaby.png'),
            eventTitle: 'Lil Baby',
            eventDate: 'July 27th',
            eventTime: '10:00pm',
          },
          {
            id: 1,
            name: 'E11even',
            district: 'Brickell',
            venueimg: require('@/assets/images/eleven.png'),
            eventimg: require('@/assets/images/lilbaby.png'),
            eventTitle: 'Lil Baby',
            eventDate: 'July 27th',
            eventTime: '10:00pm',
          },
          {
            id: 1,
            name: 'E11even',
            district: 'Brickell',
            venueimg: require('@/assets/images/eleven.png'),
            eventimg: require('@/assets/images/lilbaby.png'),
            eventTitle: 'Lil Baby',
            eventDate: 'July 27th',
            eventTime: '10:00pm',
          },
          {
            id: 1,
            name: 'E11even',
            district: 'Brickell',
            venueimg: require('@/assets/images/eleven.png'),
            eventimg: require('@/assets/images/lilbaby.png'),
            eventTitle: 'Lil Baby',
            eventDate: 'July 27th',
            eventTime: '10:00pm',
          },
          {
            id: 1,
            name: 'E11even',
            district: 'Brickell',
            venueimg: require('@/assets/images/eleven.png'),
            eventimg: require('@/assets/images/lilbaby.png'),
            eventTitle: 'Lil Baby',
            eventDate: 'July 27th',
            eventTime: '10:00pm',
          },
          {
            id: 1,
            name: 'E11even',
            district: 'Brickell',
            venueimg: require('@/assets/images/eleven.png'),
            eventimg: require('@/assets/images/lilbaby.png'),
            eventTitle: 'Lil Baby',
            eventDate: 'July 27th',
            eventTime: '10:00pm',
          },
          {
            id: 1,
            name: 'E11even',
            district: 'Brickell',
            venueimg: require('@/assets/images/eleven.png'),
            eventimg: require('@/assets/images/lilbaby.png'),
            eventTitle: 'Lil Baby',
            eventDate: 'July 27th',
            eventTime: '10:00pm',
          },
          {
            id: 1,
            name: 'E11even',
            district: 'Brickell',
            venueimg: require('@/assets/images/eleven.png'),
            eventimg: require('@/assets/images/lilbaby.png'),
            eventTitle: 'Lil Baby',
            eventDate: 'July 27th',
            eventTime: '10:00pm',
          },
          // Add more event cards as needed
        ],
      };
    },
    methods: {
      updateCarousel(city) {
        this.selectedCity = city;
        // Implement your logic for updating the carousel here
      },
    },
  };
  </script>
  
  <style scoped>
  .page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: top;
    padding: 0px;
    background-color: #222222;
}
  
  .city-links {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    padding-top: 20px;
  }
  
  .city-link {
    margin: 0 10px;
    color: white;
    font-family: 'Bebas', sans-serif;
    font-size: 35px;
    cursor: pointer;
    transition: color 0.4s;
  }
  
  .city-link.selected {
    color: rgb(168, 68, 239);
  }
  
  .card-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    flex-grow: 1;
    margin-bottom: 20px;
  }
  </style>
  