<template>
  <div class="page-container">
    <p>Welcome</p>
    <p>Welcome</p>
    <p>Welcome</p>
  </div>
</template>
    
    <script>

    import LiveCard from '@/components/live/LiveCard.vue';
    export default {
    name: 'HomePage',
    components: {
      LiveCard,
    },
    
  };
  </script>
    
    <style scoped>
    .page-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: top;
      padding: 0px;
      background-color: #222222;
    }


    </style>
    