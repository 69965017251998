<template>
  <footer class="footer">
    <div class="container">
      <div class="footer-row">
        <div class="footer-column">
          <p class="footer-text">Connect with us!</p>
          <div class="icon-grid centered-icon-grid">
            <a href="#"><font-awesome-icon class="social-icon" :icon="['fab', 'facebook']" /></a>
            <a href="#"><font-awesome-icon class="social-icon" :icon="['fab', 'twitter']" /></a>
            <a href="#"><font-awesome-icon class="social-icon" :icon="['fab', 'instagram']" /></a>
            <a href="#"><font-awesome-icon class="social-icon" :icon="['fab', 'discord']" /></a>
            <a href="#"><font-awesome-icon class="social-icon" :icon="['fab', 'spotify']" /></a>
            <a href="#"><font-awesome-icon class="social-icon" :icon="['fab', 'dev']" /></a>
          </div>
          <p class="footer-text hide-on-mobile">Built with:</p>
          <div class="icon-grid hide-on-mobile">
            <a href="https://vuejs.org/"><font-awesome-icon class="tech-icon" :icon="['fab', 'vuejs']" /></a>
            <a href="https://html.spec.whatwg.org/"><font-awesome-icon class="tech-icon" :icon="['fab', 'html5']" /></a>
            <a href="https://www.w3.org/TR/CSS/#css"><font-awesome-icon class="tech-icon" :icon="['fab', 'css3']" /></a>
            <a href="https://www.javascript.com/"><font-awesome-icon class="tech-icon" :icon="['fab', 'js']" /></a>
            <a href="https://nodejs.org/en"><font-awesome-icon class="tech-icon" :icon="['fab', 'node-js']" /></a>
            <a href="https://www.npmjs.com/"><font-awesome-icon class="tech-icon" :icon="['fab', 'npm']" /></a>
          </div>
        </div>
        <div class="footer-column hide-on-mobile">
          <nav class="footer-links">
            <ul>
              <li><a href="/about">About</a></li>
              <li><a href="/venues">Venues</a></li>
              <li><a href="/live">Live</a></li>
              <li><a href="/events">Events</a></li>
              <li><a href="/">Home</a></li>
            </ul>
          </nav>
          <p style="color: #fff">&copy; {{ currentYear }} NightSync, LLC All Rights Reserved</p>
        </div>
        <div class="footer-column">
          <p class="footer-text">Contact us!</p>
          <p style="color: #fff">support@nightsync.com</p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faFacebook,
  faTwitter,
  faInstagram,
  faDev,
  faDiscord,
  faSpotify,
  faVuejs,
  faHtml5,
  faCss3,
  faJs,
  faNodeJs,
  faNpm,
} from "@fortawesome/free-brands-svg-icons";

library.add(
  faFacebook,
  faTwitter,
  faInstagram,
  faDev,
  faDiscord,
  faSpotify,
  faVuejs,
  faHtml5,
  faCss3,
  faJs,
  faNodeJs,
  faNpm
);

export default {
  name: "Footer",
  components: {
    FontAwesomeIcon,
  },
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
};
</script>

<style>
.footer {
  background-color: #111111;
  padding: 5px 0;
  text-align: center;
}

.footer-text {
  color: white;
  font-family: 'Bebas';
  font-size: 20px;
}

.footer-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.footer-column {
  flex: 1;
  margin: 10px;
}

.icon-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 10px;
  gap: 10px;
}

.centered-icon-grid {
  justify-content: center;
}

.social-icon {
  color: #ffffff;
  height: 20px;
}

.tech-icon {
  color: #fafafa;
  height: 20px;
}

.social-icon a:hover,
.tech-icon a:hover {
  color: rgb(168, 68, 239);
}

.footer-links ul {
  list-style-type: none;
  padding: 0;
}

.footer-links li {
  display: inline-block;
  margin: 0 10px;
}

.footer-links a {
  text-decoration: none;
  color: #fff;
  font-family: "Bebas";
  font-size: 40px;
}

.footer-links a:hover {
  color: rgb(168, 68, 239);
}

.contact-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-form input,
.contact-form button {
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .footer-row {
    flex-direction: column;
  }

  .footer-column {
    margin: 10px 0;
  }

  .hide-on-mobile {
    display: none;
  }
}
</style>
